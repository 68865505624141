<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="isUpdate?$t('settings.system_users.update'):$t('settings.system_users.new')"
        :paths="[{title:$t('settings.title'),link:'settings'},{title:$t('settings.sys_users'),link:'system-users'}]"
    />
    <section class="wameed-dashboard-page-content_body products_create" :key="key">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">
            <b-card-header class="align-items-center tab-title">
              <h5 class="text-med-20 text-font-main mb-0">
                {{ $t('settings.system_users.info') }}
              </h5>
              <div class="text-main text-md-20 cursor-pointer" v-if="isUpdate"
                   @click="resetPasswordModal=true">
                {{ $t('settings.system_users.reset_password') }}
              </div>
            </b-card-header>
            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                          id="name"
                          v-model="form.name"
                          :label="$t('settings.system_users.form.name')"
                          rules="required"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <TextInput
                          v-model="form.phone"
                          field-classes="w-100"
                          rules="required"
                          :label="$t('form.phone.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <TextInput
                          v-model="form.email"
                          field-classes="w-100"
                          rules="required|email"
                          :label="$t('form.email.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type="email"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                          v-model="form.role"
                          :label="$t('settings.system_users.form.role')"
                          :placeholder="$t('settings.system_users.form.name')"
                          variant="disable"
                          :items="getRoles"
                          class="wameed_dropdown"
                          title="name"
                          rules="required"

                          :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-input-file-upload
                          v-model="form.image_name"
                          fileType="images"
                          field-classes="w-100"
                          rule="required"
                          srcName='url'
                          functionType="front"
                          :uploadedFiles="form.temp_image"
                          :label="$t('users.vendor.form.profile_image')"
                          :input-placeholder="$t('form.image.placeholder')"
                          :input-append-text="$t('form.image.browse')"
                          @changed="uploadProfileImage"
                          @deleteImage="deleteProfileImage"
                      />
                    </b-col>


                  </div>


                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="isUpdate?$t('settings.system_users.update'):$t('settings.system_users.new')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>


    <warning-modal
        variant='warning'
        iconName='o-warning-icon'
        :visible="resetPasswordModal"
        @close="resetPasswordModal = false"
        @submitAction="resetPasswordAction"
        :title="$t('settings.system_users.rest_modal.title')"
        :subTitle="$t('settings.system_users.rest_modal.desc')"
        :btnTitle="$t('btn.ok')"
    />


    <warning-modal
        variant='main'
        iconName='o-sent-icon'
        :visible='showSentModal'
        @close='goBack'
        @submitAction='goBack'
        :title="$t('settings.system_users.sent.title')"
        :subTitle="$t('settings.system_users.sent.desc')"
        :btnTitle="$t('common.OK')"
    />

  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {WameedBtn, WameedDropdown, WameedForm, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import WarningModal from "@/components/WarningModal";

export default {
  components: {
    WarningModal,
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      key: 1,
      resetPasswordModal: false,
      showSentModal: false,
      form: {
        name: '',
        role: '',
        phone: '',
        email: '',
        image: [],
      },

    };
  },
  computed: {
    ...mapGetters({
      getRoles: 'admin/settings/sys_users/getRoles',
      detail: 'admin/settings/sys_users/getDetail',
      getProfileInfo: 'profile/getBasic',
    }),
    isUpdate() {
      return (this.$route.name === 'system-users-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {
    await this.$store.dispatch('admin/settings/sys_users/getRoles');
    if (this.$route.name === 'system-users-update' && this.$route.params.id) {
      this.$store.dispatch("admin/settings/sys_users/getDetail", {id: this.$route.params.id}).then(() => {

        this.form = {...this.form, ...this.detail};
        if (this.detail.image) {
          this.form.temp_image = [{url: this.detail.image}];
          this.form.image = [this.detail.image];
          this.form.image_name = this.detail.image;
        }
        this.form.role = this.getRoles.find((item) => item.id === this.detail.role_id);
      });

    } else {
      await this.$store.dispatch("admin/settings/sys_users/unsetDetail");
    }
  },


  methods: {


    goBack() {
      this.$router.push({name: 'system-users'});
    },

    uploadProfileImage(value) {
      this.key++;
      this.form.temp_image = [value];
      this.form.image = [value.url];
      this.form.image_name = value.name;
    },
    deleteProfileImage() {
      this.key++;
      this.form.temp_image = [];
      this.form.image = [];
      this.form.image_name = '';
    },
    resetPasswordAction() {
      this.$store.dispatch('admin/settings/sys_users/resetPassword', this.$route.params.id).then((item) => {
        if (item === 1) {
          setTimeout(() => {
            this.showSentModal = true
          }, 500)
        }
      }).finally(() => {
        this.resetPasswordModal = false;
      });
    },

    async submit() {

      this.form.role_id = this.form.role.id;


      if (this.$route.name === 'system-users-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        await this.$store.dispatch('admin/settings/sys_users/update', this.form);
        if (this.form.id === this.getProfileInfo.id) {
         await this.$store.dispatch('profile/basic')
        }
      } else {
        await this.$store.dispatch('admin/settings/sys_users/create', this.form);
      }

    },
  }
  ,
}
;
</script>
